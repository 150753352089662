/* shop category page */
.woocommerce-loop-category{
  &__title{
    font-size: 32px;
    padding: 0 !important;
    margin-top: 18px !important;
    margin-bottom: 10px !important;
    color: #b5b5b5;
    font-family: 'BirchStdRegular';
    .count{
      display: none;
    }
  }
}


.products{
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding-left: 0;
  list-style: none;
}

.product{
  margin-bottom: 20px;
  flex-basis: 50%;
  .woocommerce-loop-product{
    &__title{
      display: inline-block;
      padding: 0 !important;
      margin-top: 20px !important;
      margin-bottom: 10px !important;
      font-size: 22px;
      color: #b5b5b5;
      font-family: 'BirchStdRegular';
    }
    &__link{
      display: block;
      .onsale__wrap{
        position: relative;
        &:before{
          position: absolute;
          content: '';
          top: 0px;
          right: 0;
          border: 40px solid transparent;
          border-top: 40px solid @red;
          border-right: 40px solid @red;
          z-index: 1;
        }
      }
      .onsale{
        position: absolute;
        margin-top: 4px;
        margin-right: 4px;
        right: 0;
        color: white;
        font-size: 38px;
        font-family: 'BirchStdRegular';
      }
    }
  }
  .price{
    margin-top: 20px;
    float: right;
    font-size: 19px;
    font-family: 'BirchStdRegular';
    color: @red;
    ins{
      font-size: 22px;
      text-decoration: none;
    }
    del{
      text-decoration: none;
      margin-right: 30px;
      font-size: 19px;
      color: white;
      .woocommerce-Price-amount{
        position: relative;
        &:before{
          position: absolute;
          content: '';
          top: 6px;
          height: 1px;
          width: 110%;
          left: -3px;
          background: white;
          transform: rotate(-17deg);
        }
      }
    }
    .woocommerce-Price-currencySymbol{
      padding-left: 5px;
    }
  }
  a{
    &:hover,
    &:active,
    &:focus{
      text-decoration: none;
    }
  }
  img{
    width: 100%;
    height: auto;
    &:hover{
      opacity: 0.8;
    }
  }
  &:nth-child(odd){
    padding-right: 15px;
  }
  &:nth-child(even){
    padding-left: 15px;
  }
}

/* shop sidebar */
.shop-sidebar{
  font-family: 'BirchStdRegular';
  margin-bottom: 5.5em;
  margin-left: 2.25em;
  color: @gray;
  h3{
    font-family: 'BirchStdRegular';
    font-size: 32px;
    margin-bottom: 12px;
  }
  &.widget_text{
    margin-top: 5.5em;
    margin-bottom: 0;
  }
  &__title{
    font-size: 20px;
    margin-bottom: 10px;
  }
}

.woocommerce-widget-layered-nav-list{
  list-style: none;
  padding-left: 0;
  font-size: 0;
  &__item{
    position: relative;
    padding-left: 25px;
    &.filter_color{            // rewrite color filter
      margin-right: 5px;
      display: inline-block;
      padding-left: 0;
      border: 2px solid transparent;
      &.chosen{
        border: 2px solid white;
      }
      a{
        display: block;
        width: 40px;
        height: 40px;
        color: transparent;
      }
      &:before{
        display: none;
      }
      &:after{
        display: none;
      }
    }
    &:before{
      position: absolute;
      content: '';
      width: 13px;
      height: 13px;
      left: 0px;
      top: 5px;
      background: white;
      -webkit-border-radius: 90px;
      -moz-border-radius: 90px;
      border-radius: 90px;
    }
    &--chosen{
      &:after{
        position: absolute;
        content: '';
        width: 7px;
        height: 7px;
        left: 3px;
        top: 8px;
        background: black;
        -webkit-border-radius: 90px;
        -moz-border-radius: 90px;
        border-radius: 90px;
      }
    }
  }
  a{
    font-size: 20px;
    color: @gray;
    &:hover,
    &:active,
    &:focus{
      text-decoration: none;
    }
  }
  .count{
    display: none;
  }
}


// brand badge (use like image attribute)
//.br_widget_brand_element{
//  display: inline-block;
//  margin-right: 5px;
//}

//badge (new item)
.yith-wcbm-badge{
  &.yith-wcbm-badge-custom{
    position: relative;
    margin-top: -23px;
    width: auto;
    height: auto;
    font-family: 'BirchStdRegular';
    line-height: initial;
    text-align: left;
    z-index: 20000;
    background: none;
    &:before{
      position: absolute;
      content: '';
      top: -37px;
      left: 0;
      border: 30px solid transparent;
      border-bottom: 30px solid white;
      border-left: 30px solid white;
      z-index: 1;
    }
    &:after{
      position: absolute;
      z-index: 2;
      content: 'New';
      bottom: 0;
      left: 0;
      font-size: 28px;
      padding-left: 3px;
    }
  }
}

/* woocommerce-ordering */
.woocommerce-ordering{
  float: right;
  margin-top: 10px;
  margin-bottom: 10px;
  font-family: 'BirchStdRegular';
  text-align: right;
  span{
    position: relative;
    top: 2px;
    color: @gray;
    text-transform: uppercase;
    font-size: 19px;
    padding-right: 7px;
  }
}

/* woocommerce-breadcrumb */
.woocommerce-breadcrumb{
  position: relative;
  left: -65px;
  display: inline-block;
  margin-bottom: 10px;
  color: @gray;
  font-size: 32px;
  font-family: 'BirchStdRegular';
  a{
    color: white;
    &:first-child{
      color: @red;
    }
    &:hover,
    &:focus{
      text-decoration: none;
    }
  }
}

//wc pagination
.woocommerce-pagination{
  ul{
    padding-left: 0;
    text-align: center;
    li{
      padding-right: 7px;
      display: inline-block;
      font-family: 'BirchStdRegular';
      font-size: 22px;
      a{
        color: @red;
        &:hover,
        &:active{
          text-decoration: none;
        }
      }
      &:last-child{
        padding-right: 0;
      }
    }
  }
}