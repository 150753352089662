.single_work{
  &_thumb{
    position: relative;
  }
  &_nav{
    position: absolute;
    z-index: 2;
    width: 100%;
    top: 50%;
    margin-top: -76px;
    &_prev,
    &_next{
      font-size: 6em;
      color: white;
      opacity: .8;
      &:hover{
        opacity: 1;
      }
    }
    &_prev{
      float: left;
      margin-left: 20px;
      transform: rotate(180deg);
    }
    &_next{
      float: right;
      margin-right: 20px;
    }
  }
}

.single_work_title{
  display: inline-block;
  padding: 0 !important;
  margin-top: 18px !important;
  margin-bottom: 10px !important;
  h1{
    font-size: 30px;
    color: #b5b5b5;
    font-family: 'BirchStdRegular';
  }
}

.single_product.product{
  padding: 0;
}


.product-info{
  &__meta{
    display: flex;
    align-items: center;
    width: 100%;
    .price{
      margin-left: auto;
      ins{
        font-size: 30px;
      }
      del{
        font-size: 24px;
      }
    }
  }
}

.single_work_thumb {
  text-align: center;
  img{
    max-width: 100%;
    width: auto !important;
  }
}
.single_work_thumbs.single-product-thumbs{
  margin-top: 0;
}
//tabs
.tabs{
  display: none; // tabs selector
}
.wc-tab{
  h2{
    display: none;
  }
}
.woocommerce-tabs{
  margin-top: 50px;
}
