//.container{
//  width: 1170px;
//}

/* header */
.hd_logo{
  margin: 30px 0 0 2.25em;
  &_maintitle{
    text-transform: uppercase;
  }
}

.hd_title{
  &_right{
    float: right;
    margin-right: 36px;
    a{
      display: block;
      margin-top: 20px;
    }
  }
  img{
    padding-right: 10px;
  }
  a{
    &:hover,
    &:active,
    &:focus{
      text-decoration: none;
    }
  }
  img{
    margin-bottom: 10px;
  }
}

/* footer */
.ft_copy{
  text-align: left;
  a{
    color: #b5b5b5;
  }
}

.post_body{
  font-size: 19px;
}

.single_work_thumbs{
  margin-top: 65px;
}